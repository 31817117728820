<template>
  <div>
    <b-form-group
      label-cols-md="1"
      label="Customer:"
      label-align-md="right"
      label-for="customer">
      <b-form-input id="customer" v-model="formData.dd.name" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Create Date:"
      label-align-md="right"
      label-for="createDate">
      <b-form-input id="createDate" v-model="formData.createDate" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Confirmed:"
      label-align-md="right"
      label-for="confirmed">
      <b-form-checkbox
        class="cols-md-4"
        id="confirmed"
        v-model="formData.confirmed" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Sent:"
      label-align-md="right"
      label-for="sent">
      <b-form-checkbox
        class="cols-md-4"
        id="sent"
        v-model="formData.sent" />
    </b-form-group>
    <div>
      <b-button v-b-toggle:deliveryDetailsCollapse variant="primary">
        <span class="when-open">Hide </span><span class="when-closed">Show </span>
        <span>the delivery details</span>
        <i class="when-closed mdi mdi-arrow-down" />
        <i class="when-open mdi mdi-arrow-up" />
      </b-button>
      <b-collapse id="deliveryDetailsCollapse" style="padding-top: 5px;">
        <b-form-group
          label-cols-md="1"
          label="City:"
          label-align-md="right"
          label-for="city">
          <b-form-input id="city" v-model="formData.dd.city" disabled />
        </b-form-group>
        <b-form-group
          label-cols-md="1"
          label="Street:"
          label-align-md="right"
          label-for="street">
          <b-form-input id="street" v-model="formData.dd.street" disabled />
        </b-form-group>
        <b-form-group
          label-cols-md="1"
          label="Zip:"
          label-align-md="right"
          label-for="zip">
          <b-form-input id="zip" v-model="formData.dd.zip" disabled />
        </b-form-group>
        <b-form-group
          label-cols-md="1"
          label="City:"
          label-align-md="right"
          label-for="city">
          <b-form-input id="city" v-model="formData.dd.city" disabled />
        </b-form-group>
        <b-form-group
          label-cols-md="1"
          label="eMail:"
          label-align-md="right"
          label-for="email">
          <b-form-input id="email" v-model="formData.dd.email" disabled />
        </b-form-group>
        <b-form-group
          label-cols-md="1"
          label="Notice:"
          label-align-md="right"
          label-for="notice">
          <b-form-textarea id="notice" v-model="formData.dd.notice" rows="3" disabled />
        </b-form-group>
      </b-collapse>
    </div>
    <div>
      <b-button v-b-toggle:commProdDetailsCollapse variant="primary">
        <span class="when-open">Hide </span><span class="when-closed">Show </span>
        the commercial products in the basket
        <i class="when-closed mdi mdi-arrow-down" />
        <i class="when-open mdi mdi-arrow-up" />
      </b-button>
      <b-collapse id="commProdDetailsCollapse" style="padding-top: 5px;">
        <div>
          <b-row style="border-bottom: solid 1px;">
            <b-col>Image</b-col>
            <b-col>Title</b-col>
            <b-col>Prise</b-col>
            <b-col>Amount</b-col>
            <b-col></b-col>
          </b-row>
        </div>
        <div v-for="cpd in formData.cpd" :key="cpd.cp.key">
          <b-row style="margin-top: 4px;">
            <b-col><b-img :src="cpd.d.fragments.imgSrc" fluid></b-img></b-col>
            <b-col>{{ cpd.d.fragments.title }}</b-col>
            <b-col>{{ cpd.d.fragments.price }}</b-col>
            <b-col style="border-bottom: solid 1px;">{{ cpd.cp.amount }}</b-col>
            <b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col>Subtotal:</b-col>
            <b-col>{{ cpd.d.fragments.price*cpd.cp.amount }}</b-col>
            <b-col></b-col>
          </b-row>
        </div>
        <div>
          <b-row style="border-top: solid 1px;">
            <b-col></b-col>
            <b-col></b-col>
            <b-col>Total:</b-col>
            <b-col>{{ formData.basketTotal }}</b-col>
            <b-col></b-col>
          </b-row>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gl-order',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    } // initialData
  }, // props
  data () {
    return {
      formData: this.initialData ? Object.assign({}, this.initialData) : {}
    }
  }, // data
  /* ----- vujs hooks ----- */
  mounted () {}, // mounted
  /* ----- vujs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
    } // initialData
  } // watch
}
</script>

<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}</style>
